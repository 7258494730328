var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"tables"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-title"},[_vm._v("Liste des demandes d'echantillon")]),_c('div',{staticClass:"table-responsive"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control search-input",attrs:{"value":"","placeholder":"Recherche par code","type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('router-link',{attrs:{"to":"/addechantillon"}},[_c('button',{staticClass:"btn btn-info float-right",staticStyle:{"margin-top":"10px","margin-bottom":"10px"},attrs:{"id":"additem"}},[_vm._v(" Ajouter une demande ")])]),_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.searchFunction),function(echantillon,index){return _c('tr',{key:index},[_c('th',[_vm._v(_vm._s(echantillon.user_id["name"]))]),_c('td',[(echantillon.etat_demmande == 'en attente')?_c('span',{staticClass:"badge rounded-pill bg-secondary"},[_vm._v("Demande en attente")]):_vm._e(),(echantillon.etat_demmande == 'en preparation')?_c('span',{staticClass:"badge rounded-pill bg-info"},[_vm._v("Demande en preparation")]):_vm._e(),(echantillon.etat_demmande == 'refuse')?_c('span',{staticClass:"badge rounded-pill bg-danger"},[_vm._v("Demande refusé")]):_vm._e(),(
                          echantillon.etat_demmande == 'valide_magasinier'
                        )?_c('span',{staticClass:"badge rounded-pill bg-success"},[_vm._v("Demande validé")]):_vm._e(),(echantillon.etat_demmande == 'attente_stock')?_c('span',{staticClass:"badge rounded-pill bg-warning"},[_vm._v("Demande en attente du stock")]):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(echantillon.created_at)))]),_c('td',[_c('router-link',{attrs:{"to":{
                          name: 'showdemande',
                          params: { id: echantillon._id },
                        },"href":"#"}},[_c('i',{staticClass:"mdi mdi-eye icone text-info",attrs:{"title":"Afficher les détails"}})]),(echantillon.etat_demmande == 'en attente')?_c('i',{staticClass:"mdi mdi-check-circle icone text-success",attrs:{"title":"Valider la commande"},on:{"click":function($event){return _vm.valide(echantillon._id)}}}):_vm._e(),(echantillon.etat_demmande == 'en attente')?_c('i',{staticClass:"mdi mdi-close-octagon icone text-danger",attrs:{"title":"Refuser la commande","data-toggle":"modal"},on:{"click":function($event){$event.preventDefault();return _vm.refuser(echantillon._id)}}}):_vm._e(),_c('div',{staticClass:"modal fade",attrs:{"id":'test' + echantillon._id,"tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',[_vm._v(_vm._s(echantillon.user_id["name"]))]),_vm._m(1,true)]),_c('div',{staticClass:"modal-body"},[_vm._m(2,true),_c('table',{staticClass:"table table-bordered"},[_vm._m(3,true),_c('tbody',_vm._l((echantillon.produits),function(produit,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(produit.produit_id.reference)+" ")]),_c('td',[_vm._v(_vm._s(produit.quantites))])])}),0)])])])])])],1)])}),0)])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Delegué")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Etat du demande")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("date du demande")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"20px","margin-bottom":"20px"}},[_c('h5',[_vm._v("Les échantillons demandés")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Reference")]),_c('th',[_vm._v("Quantité demandé")])])])
}]

export { render, staticRenderFns }