<template>
  <div>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Liste des demandes d'echantillon</div>

              <div class="table-responsive">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par code"
                  type="text"
                  v-model="search"
                />
                <router-link to="/addechantillon">
                <button
                    class="btn btn-info float-right"
                    id="additem"
                    style="margin-top: 10px; margin-bottom: 10px"
                  >
                    Ajouter une demande
                  </button>
                </router-link>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Delegué</th>
                      <th scope="col">Etat du demande</th>
                      <th scope="col">date du demande</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(echantillon, index) in searchFunction"
                      :key="index"
                    >
                      <th>{{ echantillon.user_id["name"] }}</th>
                      <td>
                        <span
                          class="badge rounded-pill bg-secondary"
                          v-if="echantillon.etat_demmande == 'en attente'"
                          >Demande en attente</span
                        >

                        <span
                          class="badge rounded-pill bg-info"
                          v-if="echantillon.etat_demmande == 'en preparation'"
                          >Demande en preparation</span
                        >

                        <span
                          class="badge rounded-pill bg-danger"
                          v-if="echantillon.etat_demmande == 'refuse'"
                          >Demande refusé</span
                        >
                        <span
                          class="badge rounded-pill bg-success"
                          v-if="
                            echantillon.etat_demmande == 'valide_magasinier'
                          "
                          >Demande validé</span
                        >
                        <span
                          class="badge rounded-pill bg-warning"
                          v-if="echantillon.etat_demmande == 'attente_stock'"
                          >Demande en attente du stock</span
                        >
                      </td>

                      <td>{{ echantillon.created_at | formatDate }}</td>

                      <td>
                        <router-link
                          :to="{
                            name: 'showdemande',
                            params: { id: echantillon._id },
                          }"
                          href="#"
                        >
                        <i
                          class="mdi mdi-eye icone text-info"
                          title="Afficher les détails"
                      
                        >
                        </i>
                        </router-link>

                        <i
                          class="mdi mdi-check-circle icone text-success"
                          title="Valider la commande"
                          @click="valide(echantillon._id)"
                          v-if="echantillon.etat_demmande == 'en attente'"
                        >
                        </i>
                       
                          <i
                            class="mdi mdi-close-octagon icone text-danger"
                            title="Refuser la commande"
                            data-toggle="modal"
                            @click.prevent="refuser(echantillon._id)"
                            v-if="echantillon.etat_demmande == 'en attente'"

                          >
                          </i>
                      
                        <!-- Button trigger modal -->

                        <!-- Modal -->

                        

                        <div
                          class="modal fade"
                          :id="'test' + echantillon._id"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5>{{ echantillon.user_id["name"] }}</h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div
                                  style="margin-top: 20px; margin-bottom: 20px"
                                >
                                  <h5>Les échantillons demandés</h5>
                                </div>
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Reference</th>
                                      <th>Quantité demandé</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr
                                      v-for="(
                                        produit, index
                                      ) in echantillon.produits"
                                      :key="index"
                                    >
                                      <td>
                                        {{ produit.produit_id.reference }}
                                      </td>
                                      <td>{{ produit.quantites }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <!-- <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button type="button" class="btn btn-primary">
                                    Save changes
                                  </button>
                                </div> -->
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  name: "basicTables",
  data() {
    return {
      echantillons: [],
      search: "",
      change: "",
      produits: [],
      quantite_total: "",
      neww: 0,
      produit_selected: "",
    };
  },
  mounted() {
    this.change = "oui";
  },
  created() {
    this.getechantillons();
  },
  computed: {
    searchFunction() {
      return this.echantillons.filter((item) => {
        return item._id.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  methods: {

    getechantillons() {
      HTTP.get("echantillons/getEchantillons")
        .then((response) => {
          this.echantillons = response.data;
        })
        .then((err) => console.log(err));
    },
    valide(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez valider la demande",
        icon: "success",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.put("echantillons/valideEchantillon/" + id).then((response) => {
            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "demande d'echantillon en préparation",
            });
            this.getechantillons();
          });
        }
      });
    },

    refuser(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez refuser la demande",
        icon: "success",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP.put("echantillons/refuseEchantillon/" + id).then((response) => {
            Toast.fire({
              position: "top-center",
              icon: "success",
              title: "demande d'echantillon refusé",
            });
            this.getechantillons();
          });
        }
      });
    },


    refuse(id) {
      HTTP.post("echantillons/refuse/" + id, this.echantillons).then((response) => {});
    },
  },
};
</script>
